import * as React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Grid from '@material-ui/core/Grid'
import Layout from '../components/layout'
import Seo from '../components/seo'
import priceforecasts from '../assets/images/priceforecasts.png'
import predictiveanalytics from '../assets/images/predictiveanalytics.png'
import quantitativealgos from '../assets/images/quantitativealgos.png'
import visualmarketdepth from '../assets/images/visualmarketdepth.png'
import documentedaccuracy from '../assets/images/documentedaccuracy.png'
import intravisuals from '../assets/images/intravisuals.png'

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class FeaturesPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewportWidth: isClient ? window.innerWidth : 0,
    }
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }
  componentWillUnmount() {
    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'Features'

    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    var textcol = 6
    var imagecol = 4
    var spacercol = 2
    if (isMobile) {
      textcol = 12
      imagecol = 12
      spacercol = 0
    }

    return (
      <React.Fragment>
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h1 className="generalpage_majorheading">{pageName}</h1>
              </Grid>
              <Grid item xs={12}>
                <div className="featurescontainer">
                  <Grid container spacing={0}>
                    <Grid item xs={textcol}>
                      <h2 className="features_h2">Price Forecasts</h2>
                    </Grid>
                    <Grid item xs={imagecol} />
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={imagecol} className="featuresimagecontainer">
                      <img className="featuresimage" src={priceforecasts} />
                    </Grid>
                    <Grid item xs={textcol}>
                      <div className="features_text">
                        A price forecast based on quantitative statistics,
                        seasonality, probability, and historical pattern
                        recognition brings definite edge to trading decisions.
                        Knowing a price prediction for the security you’re
                        trading assists in stop-loss management, risk
                        determination and overall confidence in your decision
                        making, maximising returns.
                      </div>
                    </Grid>
                    <Grid item xs={spacercol} />
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="featurescontainer">
                  <Grid container spacing={0}>
                    <Grid item xs={textcol}>
                      <h2 className="features_h2">Quantitative Analytics</h2>
                    </Grid>
                    <Grid item xs={imagecol} />
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={imagecol} className="featuresimagecontainer">
                      <img
                        className="featuresimage"
                        src={predictiveanalytics}
                      />
                    </Grid>
                    <Grid item xs={textcol}>
                      <div className="features_text">
                        Quantitative analytics is more than just “mirroring the
                        past” and utilising a few indicators. Incorporating a
                        variety of statistical techniques that analyse current
                        and historical patterns, the EdgeForecaster quantitative
                        analytics have been designed to augment price forecasts,
                        provide insight into current market phases and
                        illustrate how best to allocate trading resources to
                        achieve optimal results.
                      </div>
                    </Grid>
                    <Grid item xs={spacercol} />
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="featurescontainer">
                  <Grid container spacing={0}>
                    <Grid item xs={textcol}>
                      <h2 className="features_h2">Bespoke Algorithms</h2>
                    </Grid>
                    <Grid item xs={imagecol} />
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={imagecol} className="featuresimagecontainer">
                      <img className="featuresimage" src={quantitativealgos} />
                    </Grid>
                    <Grid item xs={textcol}>
                      <div className="features_text">
                        Over their decades of experience trading financial
                        markets and working for quantitative hedge funds, the
                        creators of EdgeForecaster have developed hundreds of
                        custom algorithms to measure different aspects of market
                        behaviour and pinpoint the optimal time to trade. Many
                        of these algorithms have been included in the initial
                        release of EdgeForecaster with more to come as the
                        automated trading feature is released later this year.
                      </div>
                    </Grid>
                    <Grid item xs={spacercol} />
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="featurescontainer">
                  <Grid container spacing={0}>
                    <Grid item xs={textcol}>
                      <h2 className="features_h2">Visual Market Depth</h2>
                    </Grid>
                    <Grid item xs={imagecol} />
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={imagecol} className="featuresimagecontainer">
                      <img className="featuresimage" src={visualmarketdepth} />
                    </Grid>
                    <Grid item xs={textcol}>
                      <div className="features_text">
                        Plotting the market depth allows a trader to instantly
                        see who has control of the price action. In addition to
                        the visual plot, EdgeForecaster creates a
                        second-by-second profile of the pricing action to assist
                        in identifying market phase and underlying trends.
                        Understanding and detecting patterns within a market
                        depth dramatically improves the accuracy of price
                        forecasts and the trading edge delivered through
                        EdgeForecaster
                      </div>
                    </Grid>
                    <Grid item xs={spacercol} />
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="featurescontainer">
                  <Grid container spacing={0}>
                    <Grid item xs={textcol}>
                      <h2 className="features_h2">Documented Accuracy</h2>
                    </Grid>
                    <Grid item xs={imagecol} />
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={imagecol} className="featuresimagecontainer">
                      <img className="featuresimage" src={documentedaccuracy} />
                    </Grid>
                    <Grid item xs={textcol}>
                      <div className="features_text">
                        Forecastability is the metric EdgeForecaster creates to
                        indicate how accurately its models are forecasting each
                        security on the EdgeForecaster platform. Users can see
                        the recent history of price forecast accuracy and can
                        search accuracy metrics over time, allowing them to find
                        securities and sectors which are performing better than
                        others. EdgeForecaster also uses the Forecastability
                        metric to finetune parameters in price prediction models
                        to maximise the probability of an accurate forecast.
                      </div>
                    </Grid>
                    <Grid item xs={spacercol} />
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="featurescontainer">
                  <Grid container spacing={0}>
                    <Grid item xs={textcol}>
                      <h2 className="features_h2">
                        Intra-Period Visualisations
                      </h2>
                    </Grid>
                    <Grid item xs={imagecol} />
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={imagecol} className="featuresimagecontainer">
                      <img className="featuresimage" src={intravisuals} />
                    </Grid>
                    <Grid item xs={textcol}>
                      <div className="features_text">
                        One of the most common mistakes traders make is to chart
                        prices on a single, aggregated time frame. This
                        overlooks so much integral data from within that time
                        period. Whether its daily, hourly or 15minute
                        timeframes, important trade signal data is averaged or
                        discarded in every bar. EdgeForecaster has developed
                        algorithms to monitor this intra-period data and ways to
                        visualise the results so that users can pinpoint the
                        optimum time to execute their trades.
                      </div>
                    </Grid>
                    <Grid item xs={spacercol} />
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(FeaturesPage)
export const FeaturesPageQuery = graphql`
  query FeaturesPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
  }
`
